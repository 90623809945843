import * as styles from "../styles/smittevern.module.scss";
import ContentMargins from "../components/layout/ContentMargins";
import Layout from "../components/layout/layout";
import PageTitle from "../components/contentHeadings/PageTitle";
import React from 'react';
import Seo from "../components/Seo";
import { StaticImage } from "gatsby-plugin-image";

const SmittevernPage = () => {

    const plakat = <StaticImage
        src={"../images/smittevern/plakat2.jpg"}
        alt={"Smittevernplakat"}
        width={900}
        sizes={"(min-width: 960px): 900px, calc(100vw - 64px)"}
    />;

    return(
        <Layout>
            <ContentMargins>
                <Seo title={"Smittevern"} />
                <div className={styles.topSpacer}/>
                <PageTitle>Smittevern</PageTitle>
                <p>Vi følger reglene for kunne holde åpent og FHIs øvrige anbefalinger, så dere kan være sikre på en trygg og god opplevelse i salongen.</p>
                <p>Alle de ansatte fikk grundig opplæring i de nye rutinene før åpning. I praksis hadde vi så strenge hygienetiltak fra før av, at tiltakene som er utarbeidet av folkehelseinstituttet krevde få endringer. Desinfisering av utstyr og hender før og etter hver kunde gjør vi (naturligvis) også til vanlig. Nytt er mer hyppig renhold av kontaktpunkter som betalingsterminal, dørhåndtak, tastatur osv.</p>
                <p>Nytt er også at de ansatte må holde én meters avstand fra hverandre og fra andre ansattes kunder. Vi har sørget for at alle behandlingsbenkene og stolene i venteområdet står langt nok unna hverandre.</p>
                <p>Vi har satt ut desinfeksjonsmiddel i venteområdet som dere kan benytte dere av, og installert papirdispensere på toalettene.</p>
                <p>Vi har også fjernet pledd og tepper fra stasjonene, og spriter benkene mellom hver kunde. Ta gjerne med ditt eget pledd, eller legg jakken din over deg dersom du er redd for å bli kald.</p>
                <p>Det er flott om dere i denne perioden kan unngå å ta med venner (som ikke skal ha behandling selv) og forsøker å komme presist til timen. På denne måten unngår vi at det er unødvendig mange i salongen samtidig.</p>
                <p>Husk at du ikke må komme til lokalet hvis du har feber, symptomer på luftveisinfeksjon eller er i karantene. Kontakt oss på telefon (480 77 349) så hjelper vi deg med å flytte timen din.</p>
                <p>Vi gleder oss til å se dere i salongen!</p>
                <p>Velkommen!</p>
                <div className={styles.topSpacer}/>
                <p>Du kan se reglene som gjelder for vår bransje i covid-19-forskriften <a href={"https://lovdata.no/forskrift/2020-03-27-470/§15"}>her</a> (lovdata).</p>
                { plakat }
            </ContentMargins>
        </Layout>
    )
};

export default SmittevernPage;